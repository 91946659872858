import { useFormik } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Grid,
  Text,
  TextInput,
  Center,
  Button,
  Select,
  NumberInput
} from '@mantine/core'
// @components
import ModalBase from '../../../shared/components/Modals/ModalBase'
import axiosInstance from '../../../helpers/axios'

const COLOR_DICT = [
  {
    label: 'Amarillo',
    value: 1
  },
  {
    label: 'Azul',
    value: 2
  },
  {
    label: 'Azul metalico',
    value: 3
  },
  {
    label: 'Beige',
    value: 4
  },
  {
    label: 'Blanco',
    value: 5
  },
  {
    label: 'Burdeo',
    value: 6
  },
  {
    label: 'Cafe',
    value: 7
  },
  {
    label: 'Cafe claro',
    value: 8
  },
  {
    label: 'Calipso',
    value: 9
  },
  {
    label: 'Celeste',
    value: 10
  },
  {
    label: 'Combinación',
    value: 11
  },
  {
    label: 'Dorado',
    value: 12
  },
  {
    label: 'Gris',
    value: 13
  },
  {
    label: 'Marfil',
    value: 14
  },
  {
    label: 'Marron',
    value: 15
  },
  {
    label: 'Naranjo',
    value: 16
  },
  {
    label: 'Negro',
    value: 17
  },
  {
    label: 'No definido',
    value: 18
  },
  {
    label: 'Plateado',
    value: 19
  },
  {
    label: 'Rojo',
    value: 20
  },
  {
    label: 'Rojo cobre',
    value: 21
  },
  {
    label: 'Rojo rubi',
    value: 22
  },
  {
    label: 'Sin informacion',
    value: 23
  },
  {
    label: 'Verde',
    value: 24
  },
  {
    label: 'Verde claro',
    value: 25
  },
  {
    label: 'Verde metalico',
    value: 26
  },
  {
    label: 'Verde petroleo',
    value: 27
  },
  {
    label: 'Violeta',
    value: 28
  },
  {
    label: 'Plateado metalico',
    value: 29
  }
]

const INTERES_ASEGURABLE_DICT = [
  {
    label: 'Beneficiario empresa',
    value: 1
  },
  {
    label: 'Conyuge o pareja',
    value: 2
  },
  {
    label: 'Dueño o propietario',
    value: 3
  },
  {
    label: 'Hijos',
    value: 4
  },
  {
    label: 'Padres',
    value: 5
  },
  {
    label: 'Representante legal',
    value: 6
  },
  {
    label: 'Tutor legal',
    value: 7
  },
  {
    label: 'Usuario',
    value: 8
  },
  {
    label: 'No hay Interes',
    value: 9
  }
]

const USO_ESPECIFICO_DICT = [
  {
    label: 'Particular',
    value: 1
  },
  {
    label: 'Taxi basico',
    value: 2
  },
  {
    label: 'Taxi colectivo',
    value: 3
  },
  {
    label: 'Taxi ejecutivo',
    value: 4
  },
  {
    label: 'Transporte pasajeros',
    value: 5
  },
  {
    label: 'Transporte escolar',
    value: 6
  },
  {
    label: 'Transporte explosivos',
    value: 7
  },
  {
    label: 'Arriendo o rent a car',
    value: 8
  },
  {
    label: 'Emergencia',
    value: 9
  },
  {
    label: 'Furgon escolar',
    value: 10
  },
  {
    label: 'Transporte materiales explosivos, corrosivos o combustibles',
    value: 11
  },
  {
    label:
      'Transporte de mercancia inflamable o peligrosa, solida, liquido o gaseoso',
    value: 12
  },
  {
    label: 'Transporte percheros de guerra',
    value: 13
  },
  {
    label: 'Transporte de troncos',
    value: 14
  },
  {
    label: 'Vehiculo de uso en aeropuerto o puerto',
    value: 15
  },
  {
    label: 'Vehiculo de uso en minas',
    value: 16
  },
  {
    label: 'Moto de uso en caminos no habilitados',
    value: 17
  },
  {
    label: 'Moto de uso en competencias o rally',
    value: 18
  },
  {
    label: 'Moto de uso en repartición',
    value: 19
  },
  {
    label: 'Otros',
    value: 20
  }
]

function BciEmailVerificationModal({ open, handleClose, insurance }) {
  const [isLoading, setIsLoading] = useState(false)
  // const navigate = useNavigate()
  const { uniqueTupleId } = useParams()

  const formik = useFormik({
    initialValues: {
      chasisCode: '',
      motorCode: '',
      kmCount: '',
      color: '',
      interesAsegurado: '',
      usoEspecifico: ''
    },
    onSubmit: async (formValues, { resetForm }) => {
      setIsLoading(true)
      const { data } = await axiosInstance.post(
        `/client-types-has-insurance-types/fid_validate`,
        {
          emailCode: formValues.emailCode,
          dealId: insurance.fidDealID,
          uniqueTupleId
        }
      )
      // redirect to data.paymentPageUrl
      window.location.href = data.paymentRegisterPortal
      // navigate(`/cotiza/${uniqueTupleId}/exito`)
      setIsLoading(false)
      resetForm()
    }
  })

  return (
    <ModalBase
      closeOnClickOutside={false}
      closeOnEscape={false}
      opened={open}
      onClose={handleClose}
      title="Completar formulario BCI"
    >
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Grid.Col span={12}>
          <Text>
            Completa la siguiente información adicional para terminar la
            contratación.
          </Text>
        </Grid.Col>
        <Grid.Col span={12}>
          <NumberInput
            placeholder="KM"
            hideControls
            name="kmCount"
            value={formik.values.kmCount}
            onChange={formik.handleChange}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            placeholder="Codigo del chasis"
            name="chasisCode"
            onChange={formik.handleChange}
            value={formik.values.chasisCode}
            error={formik.touched.chasisCode && formik.errors.chasisCode}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput
            placeholder="Codigo del motor"
            name="motorCode"
            onChange={formik.handleChange}
            value={formik.values.motorCode}
            error={formik.touched.motorCode && formik.errors.motorCode}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            name="color"
            clearable
            value={formik.values.color}
            onChange={formik.handleChange}
            placeholder="Color del vehiculo"
            nothingFound="Sin resultados"
            data={COLOR_DICT}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            name="interesAsegurado"
            clearable
            value={formik.values.interesAsegurado}
            onChange={formik.handleChange}
            placeholder="Interes asegurable"
            nothingFound="Sin resultados"
            data={INTERES_ASEGURABLE_DICT}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Select
            name="usoEspecifico"
            clearable
            value={formik.values.usoEspecifico}
            onChange={formik.handleChange}
            placeholder="Uso especifico"
            nothingFound="Sin resultados"
            data={USO_ESPECIFICO_DICT}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Center>
            <Button onClick={formik.handleSubmit} loading={isLoading}>
              Validar
            </Button>
          </Center>
        </Grid.Col>
      </Grid>
    </ModalBase>
  )
}

BciEmailVerificationModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  insurance: PropTypes.object
}

BciEmailVerificationModal.defaultProps = {
  handleClose: () => null,
  open: false,
  insurance: {}
}

export default BciEmailVerificationModal
